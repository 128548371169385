.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-roboto-mono {
  font-family: 'Roboto Mono', monospace;
}

.top-slant {
  clip-path: polygon(0 50px, 100% 0,100% 100%, 0 calc(100% - 50px));
}

.top-slant-reverse {
  clip-path: polygon(0 0, 100% 50px,100% 100%, 0 100%);
}

.max-w-3xs {
  max-width: 6rem;
}

.max-w-2xs {
  max-width: 8rem;
}

.icon-sm {
  height: 40px;
}

.icon-xs {
  height: 15px;
}

.slide-in {
  transition: 1s;
  left: 0;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes wipeEnter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}